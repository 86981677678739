import React, {useEffect, useState} from 'react';
import './UpdateOneProduct.css'
import {deleteOneProduct, fetchOneProduct, updateProduct} from "../../http/productAPI";
import {useNavigate, useParams} from "react-router-dom";
import ok from "../../accets/img/ok.png";
import del from "../../accets/img/delete.png";
import arr_left from "../../accets/img/arr_left.png";

const UpdateOneProduct = () => {

    const {id} = useParams()

    const navigate = useNavigate()
    const [oneProd, setOneProd] = useState({})
    const [nameprod, setNameprod] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        fetchOneProduct(id).then(data => {
            setOneProd(data)
            setNameprod(data.name)
            setPrice(data.price)
            setDescription(data.description)
        })
    }, [id])

    const updateOneProduct = {
        id: oneProd.id,
        name: nameprod,
        description: description,
        price: price,
        categoryId: oneProd.categoryId,
        subcategoryId: oneProd.subcategoryId,
        img: oneProd.img
    }

    const updateOneProductBtn = (updateOneProduct) => {
        updateProduct(updateOneProduct)
        navigate('/adminpanel/update')
    }

    const deleteProductBtn = () => {
        deleteOneProduct(oneProd.id)
        navigate('/adminpanel/update')
    }

    const cancelProductBtn = () => {
        navigate('/adminpanel/update')
    }

    return (
        <div className="admin_panel_block">
            <div className="admin_panel_txt">Редактирование товара</div>
            <div className="admin_panel">
                <div className="admin_panel_line_one">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Название товара</div>
                        <div className="admin_panel_input_block admin_panel_input_block_redact">
                            <input type="text" value={nameprod} onChange={e => setNameprod(e.target.value)}/>
                        </div>
                    </div>
                    <div className="admin_panel_btn_block">
                        <div className="admin_panel_btn_add">
                            <img src={ok} alt={ok}/>
                            <div onClick={() => updateOneProductBtn(updateOneProduct)}
                                 className="admin_panel_btn_txt">Сохранить
                            </div>
                        </div>
                    </div>
                    <div className="admin_panel_btn_block">
                        <div className="admin_panel_btn_add admin_panel_btn_add_del">
                            <img src={del} alt={del}/>
                            <div onClick={deleteProductBtn}
                                 className="admin_panel_btn_txt">Удалить
                            </div>
                        </div>
                    </div>
                    <div className="admin_panel_btn_block">
                        <div className="admin_panel_btn_add">
                            <img src={arr_left} alt={arr_left}/>
                            <div onClick={cancelProductBtn}
                                 className="admin_panel_btn_txt">Отмена
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Характеристика товара</div>
                        <div className="admin_panel_input_block">
                            <textarea value={description} onChange={e => setDescription(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Цена</div>
                        <div className="admin_panel_input_block admin_panel_input_block_price">
                            <input type="text" value={price} onChange={e => setPrice(e.target.value)}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateOneProduct;