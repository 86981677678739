import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import arrow from '../../accets/img/arrow.png'
import './Category.css'
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const Category = observer(() => {

    const {product} = useContext(Context)

    return (
        <div className="nav__and__product">
            <div className="ctg__block">
                {product.category.map(category =>
                    <NavLink key={category.id} onClick={() => product.setSelectedCategory(category)} className="ctg__link" to='/category/product'>
                        <img src={process.env.REACT_APP_API_URL + category.img} alt={category.img}/>
                        <div className="ctg__name__block">
                            <div className="ctg__arrow">
                                <img src={arrow} alt={arrow}/>
                            </div>
                            <div className="ctg__name">{category.name}</div>
                        </div>
                        <div className="ctg__hover"></div>
                    </NavLink>
                )}
            </div>
        </div>
    );
});

export default Category;