import {$api} from "../http";

export default class AuthService {
    static async login(phone) {
        return  $api.post('api/user/login', {phone})
    }

    static async registration(phone, name) {
        return  $api.post('api/user/registration', {phone, name})
    }

    static async logout() {
        return  $api.post('api/user/logout')
    }

}