import React, {useContext, useEffect, useState} from 'react';
import './Login.css'
import {NavLink, useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import './PhoneStyle.css'
import PhoneInput from "react-phone-input-2";
import ErrorLogin from "../ErrorLogin/ErrorLogin";
import Preload from "../Preload/Preload";
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {auth} from "../../firebase/firebase";

const Login = observer(() => {

    const navigate = useNavigate()

    const {user} = useContext(Context)
    const history = useNavigate()
    const [phone, setPhone] = useState('')
    const [otpL, setOtpL] = useState('')
    const [userOtp, setUserOtp] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [show, setShow] = useState(false)
    const [visOtp, setVisOtp] = useState(false)
    const [nav, setNav] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('token') && user.isAuth) {
            navigate('/category/product')
        }
    }, [])

    const handleSubmit = event => {
        event.preventDefault();
    }

    const signIn = () => {
            user.login(phone, setHasError, setVisOtp)
    }

    const sendOtp = async () => {
        try {
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
                'size': 'invisible',
                'callback': (response) => {
                    signIn()
                },
                'expired-callback': () => {
                }
            })
            const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha)
            setUserOtp(confirmation)
        } catch (e) {
            console.error(e)
        }
    }

    const verifyOtpBtn = async () => {
        try {
            const data = await userOtp.confirm(otpL)
            user.setUserFirebase(data.user.providerData)
            setNav(true)
        } catch (e) {
            console.error(e)
        }
    }

    if (nav) {
        history('/category/product')
    }

    if (user.loading) {
        return <Preload/>
    }

    return (
        <div className="logins__block">
            <div className="box">
                <span className="borderLine"></span>
                <form onSubmit={handleSubmit}>
                    <h2>Авторизация</h2>
                    {!visOtp && <PhoneInput
                        country={'ru'}
                        value={phone}
                        onChange={(phone) => setPhone("+" + phone)}
                    />}
                    {visOtp && <div className="inputBox">
                        <input value={otpL} onChange={e => setOtpL(e.target.value)}
                               type="text"
                               required="required"/>
                        <span>Код из СМС</span>
                        <i></i>
                    </div>}
                    <div className="links">
                        {/*    <a href="#">Восстановить пароль</a>*/}
                        {!visOtp && <NavLink to="/registration">Регистрация</NavLink>}
                    </div>
                    <div id="recaptcha"></div>
                    {!visOtp && <input onClick={sendOtp} type="submit" value="Получить код"/>}
                    {visOtp && <input onClick={verifyOtpBtn} type="submit" value="Подтвердить"/>}
                </form>
            </div>
            {hasError && <ErrorLogin show={show} setShow={setShow} hasError={hasError} setHasError={setHasError}/>}
        </div>
    );
});

export default Login;