import {makeAutoObservable} from "mobx";
import AuthService from "../service/AuthService";
import axios from 'axios'

export default class UserStore {
    isAuth = false
    loading = false
    user = {}
    userFirebase = {}

    constructor() {

        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this.isAuth = bool
    }

    setLoading(bool) {
        this.loading = bool
    }


    setUser(user) {
        this.user = user
    }

    setUserFirebase(userFirebase) {
        this.userFirebase = userFirebase
    }

    async login(phone, setHasError, setVisOtp) {
        try {
            const {data} = await AuthService.login(phone)
            localStorage.setItem('token', data.accessToken)
            this.setIsAuth(true)
            this.setUser(data.user)
            setVisOtp(true)
        } catch (e) {
            setHasError(true)
        }
    }

    async registr(phone, name, setHasError, setVisOtp) {
        try {
            const {data} = await AuthService.registration(phone, name)
            localStorage.setItem('token', data.accessToken)
            setVisOtp(true)
            this.setIsAuth(true)
            this.setUser(data.user)
        } catch (e) {
            setHasError(true)
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout()
            localStorage.removeItem('token')
            this.setIsAuth(false)
            this.setUser({})
            this.setUserFirebase({})
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async checkAuth() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/user/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.accessToken)
            this.setIsAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }


}