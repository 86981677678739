import React from 'react';
import '../BasketZero/BasketZero.css'
import {NavLink} from "react-router-dom";
import logo_mini from "../../accets/img/Logo_mini.png";

const ErrorReg = ({show, setShow, hasError, setHasError}) => {

    const exitErrorLogin = () => {
        setShow(false)
        setHasError(false)
    }

    return (
        <div style={{display: show || hasError ? 'flex' : 'none'}} className='basket_zero_block'>
            <div className="basket_zero basket_zero_err">
                <img src={logo_mini} alt={logo_mini}/>
                <div>
                    <div className="error_text">Пользователь с таким телефоном уже зарегистрирован</div>
                    <div onClick={exitErrorLogin} className="basket_zero_close"></div>
                    <div className="error_link">
                        <h4>проверьте номер телефона или</h4>
                        <NavLink to='/login'>Авторизуйтесь</NavLink>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ErrorReg;