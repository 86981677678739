import React, {useContext, useEffect, useState} from 'react';
import './Order.css'
import task from '../../accets/img/task.png'
import logo_mini from '../../accets/img/Logo_mini.png'
import ok from "../../accets/img/ok.png";
import cooking from "../../accets/img/cooking.png";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../index";
import {fetchOneOrder, updateOrderEnd, updateOrderJob} from "../../http/orderAPI";
import {fetchProduct} from "../../http/productAPI";
import {fetchAddress} from "../../http/addressAPI";
import {fetchUsers} from "../../http/userAPI";
import reset from '../../accets/img/reset.png'

const Order = () => {

    const {product, user, adr} = useContext(Context)

    let {id} = useParams()

    const navigate = useNavigate()

    const [oneOrder, setOneOrder] = useState({info: []})
    const [allUsers, setAllUsers] = useState([])

    useEffect(() => {
        fetchProduct(null, null, 1, 10).then(data => {
            product.setProduct(data.rows)
            product.setTotalCount(data.count)
        })
        fetchAddress().then(data => adr.setAllAddress(data))
        fetchUsers().then(data => setAllUsers(data))
    }, [])

    useEffect(() => {
        fetchOneOrder(id).then(data => setOneOrder(data))
    }, [id])

    let statusText
    let jobBtnVisible
    let payText

    if (oneOrder.pay == '1' && !oneOrder.summ) {
        payText = <div className='pay_block'>
            <div className='pay'>Оплата наличными</div>
        </div>
    }

    if (oneOrder.pay == '1' && oneOrder.summ) {
        payText = <div className='pay_block'>
            <div className='pay'>Оплата наличными</div>
            <div className='summ'>Сдача с</div>
            <div className='summ'>{oneOrder.summ}</div>
            <div className='summ'>рублей</div>
        </div>
    }

    if (oneOrder.pay == '2') {
        payText = <div className='pay_block'>
            <div className='pay'>Оплата картой курьеру</div>
        </div>
    }

    if (oneOrder.status === "NEW") {
        statusText = <div className="status">Новый</div>
        jobBtnVisible = <div onClick={() => jobBtn(orderJob)} className="btn__order btn__order__job">
            <img src={cooking} alt={cooking}/>
            <div className="btn__order__txt">В работу</div>
        </div>
    }

    if (oneOrder.status === "JOB") {
        statusText = <div className="status">В работе</div>
    }

    if (oneOrder.status === "END") {
        statusText = <div className="status">Выполнен (скоро доставим)</div>
        jobBtnVisible = <div onClick={() => jobBtn(orderJob)} className="btn__order btn__order__job">
            <img src={cooking} alt={cooking}/>
            <div className="btn__order__txt">В работу</div>
        </div>
    }

    const userInOrder = allUsers.filter(obj => obj.id === oneOrder.userId)

    const resultProdInfo = oneOrder.info.map((info) => {
        const withProductId = product.product.filter(prod => prod['id'] === info['productId'])
        const prod = withProductId.reduce((acc, current) => {
            acc.push(current)
            return acc
        }, [])
        return {...info, product: prod}
    })

    const resultTotal = resultProdInfo.map((info) => {
        const prod = info.product.reduce((acc, current) => {
            acc = acc + current.price
            return acc
        }, 0)
        return {count: info.count, product: prod}
    })

    const orderJob = {id: oneOrder.id, status: "JOB", userId: oneOrder.userId, addressId: oneOrder.addressId}

    const jobBtn = (orderJob) => {
        updateOrderJob(orderJob)
        fetchOneOrder(id).then(data => setOneOrder(data))
        navigate('/adminpanel/orders')
    }

    const orderEnd = {id: oneOrder.id, status: "END", userId: oneOrder.userId, addressId: oneOrder.addressId}

    const endBtn = (orderEnd) => {
        updateOrderEnd(orderEnd)
        fetchOneOrder(id).then(data => setOneOrder(data))
        navigate('/adminpanel/orders')
    }

    const resetBtn = () => {
        fetchOneOrder(id).then(data => setOneOrder(data))
    }

    let adrToFind = adr.allAddress.filter(obj => obj.id === oneOrder.addressId)

    const resultTotalSum = resultTotal.reduce((a, v) => a = a + v.product * v.count, 0)

    let totalSumPay

    const totalSum = resultTotal.reduce((a, v) => {
        a = a + v.product * v.count
        return a
    }, 0)

    if (totalSum<2000) {
        totalSumPay = totalSum + 100
    } else {
        totalSumPay = totalSum
    }


    return (
        <div className="order_block_details">
            <div className="order_block">

                <div className="name_order_block">
                    <img src={task} alt={task}/>
                    <div className="name__txt">Заказ №</div>
                    <div className="num__order">{oneOrder.id}</div>
                    <div className="name__txt">от</div>
                    <div className="date__order">{new Date(oneOrder.createdAt).toLocaleString()}</div>
                </div>
                <div className="order_all_block">
                    <div onClick={resetBtn} className="reset_block">
                        <img className="reset_block_img" src={reset} alt={reset}/>
                    </div>
                    <div className="adr_and_img">
                        <div className="adr_block_order">
                            {userInOrder.map(i =>
                                <div key={i.id} className="user_in_order_block">
                                    <div className="user_in_order_title">Заказал(а):</div>
                                    <div className="user_in_order_name">{i.name}</div>
                                </div>
                            )}
                            {userInOrder.map(i =>
                                <div key={i.id} className="user_in_order_block">
                                    <div className="user_in_order_title">Телефон:</div>
                                    <div className="user_in_order_name">{i.phone}</div>
                                </div>
                            )}
                            <div className="adr_txt">Заказ по адресу:</div>
                            {adrToFind.map(item =>
                                <div key={item.id} className="order_address">
                                    <div className="d_txt">ул.</div>
                                    <div className="street">{item.street}</div>
                                    <div className="d_txt">д.</div>
                                    <div className="d_array">{item.home}</div>
                                    <div className="d_txt">п.</div>
                                    <div className="d_array">{item.entrance}</div>
                                    <div className="kv_txt">кв.</div>
                                    <div className="kv_array">{item.apartment}</div>
                                </div>
                            )}
                            {adrToFind.map(item => {
                                    if (item.koment) {
                                        return <div key={item.id} className="order_address">
                                            <div className="d_txt">Комментрарий:</div>
                                            <div className="street">{item.koment}</div>
                                        </div>
                                    }
                                }
                            )}
                            {payText}

                            <div className="status_block">
                                <div className="status_txt">Статус заказа:</div>
                                {statusText}
                            </div>
                        </div>
                        <img className="order_all_block_img" src={logo_mini} alt={logo_mini}/>
                    </div>


                    <div className="product_order_block" id="style-3">

                        <div className="product_str_name_block">
                            <div className="product_str_name">Наименование</div>
                            <div className="prod_str_price_block">
                                <div className="prod_str_block">
                                    <div className="prod_str">Количество</div>
                                </div>
                                <div className="prod_str_block">
                                    <div className="prod_str">Цена</div>
                                </div>
                                <div className="prod_str_block">
                                    <div className="prod_str">Сумма</div>
                                </div>
                            </div>
                        </div>

                        {resultProdInfo.map(prod =>
                            <div key={prod.id}>
                                {prod.product.map(i =>
                                    <div key={i.id} className="product__name_block">
                                        <div className="product__name">{i.name}</div>
                                        <div className="product__price_block">
                                            <div className="product__block">
                                                <div className="product_str">{prod.count}</div>
                                                <div className="product_str">шт.</div>
                                            </div>
                                            <div className="product__block">
                                                <div className="product_str">{i.price}</div>
                                                <div className="product_str">руб.</div>
                                            </div>
                                            <div className="product__block">
                                                <div className="product_str">{i.price * prod.count}</div>
                                                <div className="product_str">руб.</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
                <div className="total__sum__block">
                    <div className="total__txt">Сумма заказа:</div>
                    <div className="total">{totalSumPay} Р</div>
                </div>

                {user.user.role === 'ADMIN'
                    && <div className="total__sum__block">
                        {jobBtnVisible}
                        <div onClick={() => endBtn(orderEnd)} className="btn__order btn__order__good">
                            <img src={ok} alt={ok}/>
                            <div className="btn__order__txt">Выполнен</div>
                        </div>
                    </div>
                }


            </div>
        </div>
    );
};

export default observer(Order);