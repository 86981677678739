import React from 'react';
import './NavAndProduct.css'
import Nav from "../Nav/Nav";
import {Route, Routes} from "react-router-dom";
import Product from "../Product/Product";
import ProductCat from "../Product/ProductCat";

const NavAndProduct = (
    {order,
        setOrder,
        plusOneProduct,
        minusOneProduct,
        addOrder,
        info,
        setInfo,
        addInfo,
        minusOneInfo,
        plusOneInfo}
) => {
    return (
        <div className="nav__and__product">
            <Nav order={order} setOrder={setOrder}/>
            <Routes>
                <Route path="/product" element={<Product
                    addOrder={addOrder}
                    plusOneProduct={plusOneProduct}
                    minusOneProduct={minusOneProduct}
                    order={order}
                    setOrder={setOrder}
                    info={info}
                    setInfo={setInfo}
                    addInfo={addInfo}
                    minusOneInfo={minusOneInfo}
                    plusOneInfo={plusOneInfo}
                />}/>
            </Routes>
            <Routes>
                <Route path="/productCat" element={<ProductCat
                    addOrder={addOrder}
                    plusOneProduct={plusOneProduct}
                    minusOneProduct={minusOneProduct}
                    order={order}
                    setOrder={setOrder}
                    info={info}
                    setInfo={setInfo}
                    addInfo={addInfo}
                    minusOneInfo={minusOneInfo}
                    plusOneInfo={plusOneInfo}
                />}/>
            </Routes>
        </div>
    );
};

export default NavAndProduct;