import React from 'react';
import './NavAndOrder.css'
import {Route, Routes} from "react-router-dom";
import Basket from "../Basket/Basket";
import AdminPanel from "../AdminPanel/AdminPanel";
import Order from "../Order/Order";
import UpdateOneProduct from "../UpdateOneProduct/UpdateOneProduct";
import UpdateProduct from "../UpdateProduct/UpdateProduct";
import NavAdmin from "../Nav/NavAdmin";
import Orders from "../Orders/Orders";

const NavAndOrder = (
    {order,
        setOrder,
        plusOneProduct,
        minusOneProduct,
        delProduct,
        info,
        setInfo,
        minusOneInfo,
        plusOneInfo}
) => {

    return (
        <div className="nav__and__product">
            <NavAdmin order={order} setOrder={setOrder}/>
            <Routes>
                <Route path="/basket" element={<Basket
                    delProduct={delProduct}
                    plusOneProduct={plusOneProduct}
                    minusOneProduct={minusOneProduct}
                    order={order}
                    setOrder={setOrder}
                    info={info}
                    setInfo={setInfo}
                    minusOneInfo={minusOneInfo}
                    plusOneInfo={plusOneInfo}
                />}/>
                <Route path="/orders" element={<Orders/>}/>
                <Route path="/order/:id" element={<Order/>}/>
                <Route path="/adm" element={<AdminPanel/>}/>
                <Route path="/update/" element={<UpdateProduct/>}/>
                <Route path="/update/:id" element={<UpdateOneProduct/>}/>
            </Routes>
        </div>
    );
};

export default NavAndOrder;