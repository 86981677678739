import React from 'react';
import './BasketZero.css'
import basket_zero from '../../accets/img/basket_zero.png'

const BasketZero = ({show, setShow}) => {

    const exitBasket = (e) => {
        if (e.target.classList.contains('basket_zero_block')) {
            setShow(false)
        }
    }

    return (
        <div style={{display: show ? 'flex' : 'none'}} className='basket_zero_block'>
            <div className="basket_zero">
                <img src={basket_zero} alt={basket_zero}/>
                <div className="basket_zero_text">Корзина пуста</div>
                <div onClick={() => setShow(false)} className="basket_zero_close"></div>
            </div>
        </div>
    );
};

export default BasketZero;