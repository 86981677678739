import {makeAutoObservable} from "mobx";

export default class RekStore {

    rek = []

    constructor() {

        makeAutoObservable(this)
    }

    setRek(rek) {
        this.rek = rek
    }

}