import {makeAutoObservable} from "mobx";
import AddressService from "../service/AddressService";
import {createOrder} from "../http/orderAPI";

export default class AddressStore {
    address = {}
    allAddress = []

    constructor() {

        makeAutoObservable(this)
    }

    setAddress(address) {
        this.address = address
    }

    setAllAddress(allAddress) {
        this.allAddress = allAddress
    }


    async createAddress(street, home, korp, entrance, apartment, koment, info, userId, setOrder, setInfo, pay, summ, setHasError) {
        try {
            const {data} = await AddressService.createAddress(street, home, korp, entrance, apartment, koment)
            this.setAddress(data.address)
            const formData = new FormData()
            formData.append('userId', userId)
            formData.append('addressId', data.id)
            formData.append('pay', pay)
            formData.append('summ', summ)
            formData.append('info', JSON.stringify(info))
            createOrder(formData).then(data =>
                    setOrder([]),
                setInfo([])

            )

        } catch (e) {
            setHasError(true)
        }
    }


}