import React from 'react';
import '../BasketZero/BasketZero.css'
import logo_mini from "../../accets/img/Logo_mini.png";

const ErrorAddress = ({show, setShow, hasError, setHasError}) => {

    const exitErrorLogin = () => {
        setShow(false)
        setHasError(false)
    }

    return (
        <div style={{display: show || hasError ? 'flex' : 'none'}} className='basket_zero_block'>
            <div className="basket_zero basket_zero_err">
                <img src={logo_mini} alt={logo_mini}/>
                <div>
                    <div className="error_text">Не верно или не до конца заполнен адрес</div>
                    <div onClick={exitErrorLogin} className="basket_zero_close"></div>
                    <div className="error_text">Должны быть заполнены:</div>
                    <ul className="error_link error_link_ul">
                        <li>Улица</li>
                        <li>Дом</li>
                        <li>Подъезд</li>
                        <li>Квартира</li>
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default ErrorAddress;