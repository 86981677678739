import {$host, $authHost} from "./index";

export const createCategory = async (category) => {
    const {data} = await $authHost.post('api/category', category)
    return data
}

export const fetchCategory = async () => {
    const {data} = await $host.get('api/category')
    return data
}

export const createSubcategory = async (subcategory) => {
    const {data} = await $authHost.post('api/subcategory', subcategory)
    return data
}

export const fetchSubcategory = async () => {
    const {data} = await $host.get('api/subcategory')
    return data
}

export const createProduct = async (product) => {
    const {data} = await $authHost.post('api/product', product)
    return data
}

export const fetchProduct = async (categoryId, subcategoryId, page, limit = 5) => {
    const {data} = await $host.get('api/product', {params: {
            categoryId, subcategoryId, page, limit
        }})
    return data
}

export const fetchOneProduct = async (id) => {
    const {data} = await $host.get('api/product/' + id)
    return data
}

export const deleteOneProduct = async (id) => {
    const {data} = await $host.delete('api/product/' + id)
    return data
}

export const updateProduct = async (updateOneProduct) => {
    const {data} = await $authHost.put('api/product', updateOneProduct)
    return data
}