import {$host, $authHost} from "./index";

export const createOrder = async (order) => {
    const {data} = await $authHost.post('api/order', order)
    return data
}

export const updateOrderJob = async (orderJob) => {
    const {data} = await $authHost.put('api/order', orderJob)
    return data
}
export const updateOrderEnd = async (orderEnd) => {
    const {data} = await $authHost.put('api/order', orderEnd)
    return data
}

export const fetchOrder = async () => {
    const {data} = await $host.get('api/order')
    return data
}

export const fetchOneOrder = async (id) => {
    const {data} = await $host.get('api/order/' + id)
    return data
}
