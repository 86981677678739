import React, {useContext, useEffect, useState} from 'react';
import './Header.css'
import exit from '../../accets/img/exit.png'
import bg1 from '../../accets/img/bg1.png'
import bg2 from '../../accets/img/bg2.png'
import logo from '../../accets/img/Logo.png'
import {NavLink, useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {fetchOrder} from "../../http/orderAPI";
import VisibleReklama from "../Reklama/VisibleReklama";

const Header = observer(({order}) => {

    const {user, product, rek} = useContext(Context)

    const [show, setShow] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
        fetchOrder().then(data => product.setOrder(data))
        }, 30000)
    }, [product.order])

    useEffect(() => {
        setTimeout(() => {
            if (rek.rek[0]) {
                setShow(true)
            }
            if (!rek.rek[0]) {
                setShow(false)
            }
        }, 1000)
    }, [])

    return (
        <div>
            <div className="header__nav">
                <div style={{display: user.user.role === 'ADMIN' || product.order ? 'none' : 'block'}}></div>
                {user.user.role === 'ADMIN'
                    &&
                    <div className="adm__block">
                        <NavLink className="adm_btn" to="/adminpanel/adm">Админ панель</NavLink>
                        <NavLink className="adm_btn" to="/adminpanel/orders">Заказы</NavLink>
                    </div>
                }
                <div style={{display: 'flex'}}>
                    {product.order.filter(item => item.userId === user.user.id)
                        .map(order => {
                                if (order.status === "NEW") {
                                    return <div key={order.id} onClick={() => navigate('/adminpanel/order/' + order.id)}
                                                className="adm_btn">Ваш заказ № {order.id}</div>
                                }
                                if (order.status === "JOB") {
                                    return <div key={order.id}
                                                onClick={() => navigate('/adminpanel/order/' + order.id)}
                                                className="adm_btn">
                                        Ваш заказ № {order.id}
                                    </div>
                                }
                            }
                        )}
                </div>
                {user.isAuth
                    ?
                    <div className="login__block">
                        <div className="header__login">Рады видеть Вас, {user.user.name}</div>
                        <div className="header__exit">
                            <img onClick={() => user.logout()} src={exit} alt={exit}/>
                        </div>
                    </div>
                    :
                    <div className="login__block">
                        <NavLink className="adm_btn_login" to="/login">Вход</NavLink>
                    </div>
                }
            </div>
            <div className="logo__block">
                <div className="logo__bg">
                    <img src={bg1} alt={bg1}/>
                </div>
                <div className="logo__bg logo__bg__right">
                    <img src={bg2} alt={bg2}/>
                </div>
                <img className="logo__img" src={logo} alt={logo}/>
                <div className="slogon">ЕДА, КРАФТ, НАСТРОЕНИЕ</div>
            </div>
            <VisibleReklama show={show} setShow={setShow}/>
        </div>
    );
});

export default Header;