import React, {useContext, useEffect} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {Context} from "../../index";
import './Orders.css'
import {fetchOrder} from "../../http/orderAPI";
import {observer} from "mobx-react-lite";
import {fetchAddress} from "../../http/addressAPI";
import useSound from 'use-sound'
import soundUrl from '../../accets/sound/s9.mp3'


const Orders = observer(() => {
    const {product, user, adr} = useContext(Context)
    const nav = useNavigate()

    const [play, {stop}] = useSound(soundUrl, {
        volume: 0.5,
    });

    useEffect(() => {
        setTimeout(() => {
            fetchOrder().then(data => product.setOrder(data))
            fetchAddress().then(data => adr.setAllAddress(data))
        }, 60000)
    }, [product.order, adr.allAddress])

    useEffect(() => {
        if (user.user.role !== 'ADMIN') {
            nav('/category/product')
        }
    }, [user.user.role])

    let date = new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    })

    const addressInOrder = product.order.map(order => {
        const withAddressId = adr.allAddress.filter(address => address['id'] === order['addressId'])
        const address = withAddressId.reduce((acc, curr) => {
            acc.push(curr)
            return acc
        }, [])
        return {...order, addressto: address}
    })

    if (addressInOrder.filter(i => i.status === 'NEW')[0] !== undefined) {
        play()
        setTimeout(() => {
            stop()
        }, 1000)
    }


    return (
        <div className="orders__block">
            <div className="order_new_and_g_ready">
                <div className="new__order">
                    <div className="order_txt">Новые заказы:</div>
                    <div className="new_order_block_height">
                        <div className="new_order_block">
                            {addressInOrder.filter(i => i.status === 'NEW')
                                .map(order =>
                                    <NavLink key={order.id} to={`/adminpanel/order/${order.id}`}
                                             className="order_small">
                                        <div className="num_date">
                                            <div className="num_block">
                                                <div className="num_txt">Заказ №</div>
                                                <div className="num_order">{order.id}</div>
                                            </div>
                                            <div className="date_order">{date.format(Date.parse(order.createdAt))}</div>
                                        </div>
                                        {order.addressto.map(adr =>
                                            <div key={adr.id} className="order_address">
                                                <div className="d_txt">ул.</div>
                                                <div className="street">{adr.street}</div>
                                                <div className="d_txt">д.</div>
                                                <div className="d_array">{adr.home}</div>
                                                <div className="d_txt">п.</div>
                                                <div className="d_array">{adr.entrance}</div>
                                                <div className="kv_txt">кв.</div>
                                                <div className="kv_array">{adr.apartment}</div>
                                            </div>
                                        )}
                                    </NavLink>
                                )}
                        </div>
                    </div>
                </div>
                <div className="g_ready_order">
                    <div className="new__order">
                        <div className="order_txt order_txt_g_r">Готовятся сейчас:</div>
                        <div className="new_order_block_height new_order_block_height_g_r">
                            <div className="new_order_block">
                                {addressInOrder.filter(i => i.status === 'JOB')
                                    .map(order =>
                                        <NavLink key={order.id} to={`/adminpanel/order/${order.id}`}
                                                 className="order_small order_small_g_r">
                                            <div className="num_date num_date_g_r">
                                                <div className="num_block">
                                                    <div className="num_txt">Заказ №</div>
                                                    <div className="num_order">{order.id}</div>
                                                </div>
                                                <div
                                                    className="date_order">{date.format(Date.parse(order.createdAt))}</div>
                                            </div>
                                            {order.addressto.map(adr =>
                                                <div key={adr.id} className="order_address">
                                                    <div className="d_txt">ул.</div>
                                                    <div className="street">{adr.street}</div>
                                                    <div className="d_txt">д.</div>
                                                    <div className="d_array">{adr.home}</div>
                                                    <div className="d_txt">п.</div>
                                                    <div className="d_array">{adr.entrance}</div>
                                                    <div className="kv_txt">кв.</div>
                                                    <div className="kv_array">{adr.apartment}</div>
                                                </div>
                                            )}
                                        </NavLink>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="good_order">
                <div className="order_txt" style={{color: '#D8B164'}}>Выполненные заказы:</div>
                <div id="style-4" className="new_order_block_height new_order_block_height_good">
                    <div className="new_order_block new_order_block_good">
                        {addressInOrder.filter(i => i.status === 'END')
                            .map(order =>
                                <NavLink key={order.id} to={`/adminpanel/order/${order.id}`}
                                         className="order_small order_small_good">
                                    <div className="num_date">
                                        <div className="num_block">
                                            <div className="num_txt">Заказ №</div>
                                            <div className="num_order">{order.id}</div>
                                        </div>
                                        <div className="date_order">{date.format(Date.parse(order.createdAt))}</div>
                                    </div>
                                    {order.addressto.map(adr =>
                                        <div key={adr.id} className="order_address">
                                            <div className="d_txt">ул.</div>
                                            <div className="street">{adr.street}</div>
                                            <div className="d_txt">д.</div>
                                            <div className="d_array">{adr.home}</div>
                                            <div className="d_txt">п.</div>
                                            <div className="d_array">{adr.entrance}</div>
                                            <div className="kv_txt">кв.</div>
                                            <div className="kv_array">{adr.apartment}</div>
                                        </div>
                                    )}
                                </NavLink>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Orders;