import React, {useContext, useEffect, useState} from 'react';
import './Basket.css'
import task from '../../accets/img/task.png'
import adr_img from '../../accets/img/adr.png'
import snab from '../../accets/img/snab.png'
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {NavLink, useNavigate} from "react-router-dom";
import ErrorAddress from "../ErrorLogin/ErrorAddress";

const Basket = ({
                    order,
                    setOrder,
                    plusOneProduct,
                    minusOneProduct,
                    delProduct,
                    info,
                    setInfo,
                    minusOneInfo,
                    plusOneInfo
                }) => {

    const navigate = useNavigate()

    const {user, adr} = useContext(Context)

    const [street, setStreet] = useState('')
    const [home, setHome] = useState('')
    const [korp, setKorp] = useState('')
    const [entrance, setEntrance] = useState('')
    const [apartment, setApartment] = useState('')
    const [koment, setKoment] = useState('')
    const [pay, setPay] = useState(1)
    const [summ, setSumm] = useState('')
    const [hasError, setHasError] = useState(false)
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (!order.length) {
            navigate('/category/product')
        }
    }, [order.length])

    const userId = user.user.id

    let payNalBlock

    if (pay == '1') {
        payNalBlock = <div className="pay__req__block">
            <div className="pay__req__txt">Сдача с:</div>
            <input value={summ} onChange={e => setSumm(Number(e.target.value))} className="pay__req" type="text"/>
            <div className="pay__req__txt">рублей</div>
        </div>
    }

    const addOrder = () => {
        try {
            if (!street || !home || !entrance || !apartment) {
                setHasError(true)
            }
            if (street && home && entrance && apartment) {
                adr.createAddress(street, home, korp, entrance, apartment, koment, info, userId, setOrder, setInfo, pay, summ, setHasError)
                setOrder([])
                setInfo([])
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const date = new Date();
    const showTime = date.getHours()
    const startJob = "12"
    const endJob = "21"

    let buttonOnTime
    if (showTime < startJob || showTime > endJob) {
        buttonOnTime = <>
            <div className='btn_on_time'>Заказ можно сделать с <b>{startJob}:00</b> утра и по <b>{endJob}:00</b> вечера.
                Ждем Вас в рабочее время
            </div>
        </>
    }

    if (showTime >= startJob && showTime < endJob) {
        buttonOnTime = <>
            {user.isAuth
                ? <>
                    <div style={{marginRight: '10px'}} className='btn_on_time'>Бизнес-ланч доставляется
                        с <b>12:00</b> до <b>15:00</b></div>
                    <div className="btn__order">
                        <img src={snab} alt={snab}/>
                        <div onClick={addOrder} className="btn__order__txt">Заказать</div>
                    </div>
                </>
                : <div style={{display: 'flex', alignItems: 'flex-end'}}>
                    <div className="btn__order__text">Чтобы сделать заказ нужно:</div>
                    <NavLink to='/login' className="btn__order">
                        <div className="btn__order__txt">Авторизоваться</div>
                    </NavLink>
                </div>
            }
        </>
    }

    let totalSumPay

    const totalSum = order.reduce((a, v) => {
        a = a + v.price * v.count
        return a
    }, 0)

    if (totalSum < 2000) {
        totalSumPay = totalSum + 100
    } else {
        totalSumPay = totalSum
    }

    const plusOneArrey = (id) => {
        plusOneProduct(id)
        plusOneInfo(id)
    }

    const minusOneArrey = (id) => {
        minusOneProduct(id)
        minusOneInfo(id)
    }

    return (
        <div className="basket_adr_block">
            <div className="basket_block">
                <div className="text_img_block">
                    <img src={task} alt={task}/>
                    <h3 style={{color: '#205656'}}>Ваш заказ:</h3>
                </div>
                <div id="style-2" className="order__block">
                    {order.map(item =>
                        <div key={item.id} className="order">
                            <div className="prod_name">{item.name}</div>
                            <div className="sum__block">
                                <div className="col_block">
                                    <div className="col_txt">Кол-во:</div>
                                    <div className="col_pm">
                                        <div onClick={() => minusOneArrey(item.id)} className="col_btm">-</div>
                                        <div className="col_num">
                                            {item.count}
                                        </div>
                                        <div onClick={() => plusOneArrey(item.id)} className="col_btm">+</div>
                                    </div>
                                </div>
                                <div className="col_block">
                                    <div className="price_txt">Цена:</div>
                                    <div className="price">{item.price}</div>
                                </div>
                            </div>
                            <div onClick={() => delProduct(item.id)} className="del_prod"></div>
                        </div>
                    )}
                </div>
            </div>
            <div className="adr_block">
                <div className="text_img_block">
                    <img src={adr_img} alt={adr_img}/>
                    <h3 style={{color: '#fff'}}>Адрес доставки:</h3>
                </div>
                <div className="str__block">
                    <div className="str__txt">Улица:</div>
                    <input value={street} onChange={e => setStreet(e.target.value)} className="str__input" type="text"
                           placeholder="Введите улицу..."/>
                    <div className="str__home__block">
                        <div className="home__txt">Дом:</div>
                        <input value={home} onChange={e => setHome(e.target.value)} className="home__input"
                               type="text"/>
                        <div className="korp__txt">Корпус:</div>
                        <input value={korp} onChange={e => setKorp(Number(e.target.value))} className="home__input"
                               type="text"/>
                        <div className="pod__txt">Подъезд:</div>
                        <input value={entrance} onChange={e => setEntrance(Number(e.target.value))}
                               className="home__input" type="text"/>
                        <div className="kv__txt">Квартира:</div>
                        <input value={apartment} onChange={e => setApartment(Number(e.target.value))}
                               className="home__input" type="text"/>
                    </div>
                    <div className="komm__txt">Комментарий к адресу заказа:</div>
                    <textarea
                        value={koment}
                        onChange={e => setKoment(e.target.value)}
                        className="komm__input"
                        placeholder="Комментарий к адресу заказа..."
                        cols="30" rows="5">
                    </textarea>
                    <div className="select__pay__txt">Выберите способ оплаты:</div>
                    <input
                        type="radio"
                        id="Choice1"
                        name="radio"
                        value="1"
                        onChange={e => setPay(e.target.value)}
                        checked={pay == '1' ? true : false}/>
                    <label style={{paddingRight: '15px'}} htmlFor="Choice1">Наличными</label>
                    <input
                        type="radio"
                        id="Choice2"
                        name="radio"
                        onChange={e => setPay(e.target.value)}
                        value="2" checked={pay == '2' ? true : false}/>
                    <label htmlFor="Choice2">Безналичными (курьеру)</label>
                    {payNalBlock}
                </div>
                <div className="total__sum__block">
                    <div className="total__txt">Сумма заказа:</div>
                    <div className="total">
                        {totalSumPay}
                        Р
                    </div>
                </div>
                <div className="usl_block">
                    <div className="usl">При заказе от 2000 рублей - доставка БЕСПЛАТНАЯ.</div>
                    <div className="usl">В иных случаях - 100 рублей.</div>
                </div>
                <div className="total__sum__block">
                    {buttonOnTime}
                </div>
                {hasError &&
                    <ErrorAddress show={show} setShow={setShow} hasError={hasError} setHasError={setHasError}/>}
            </div>
        </div>
    );
};

export default observer(Basket);