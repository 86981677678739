import React, {useContext} from 'react';
import './Nav.css'
import {NavLink} from "react-router-dom";
import arrow from '../../accets/img/arrow.png'
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const NavAdmin = observer(({order}) => {

    const {product} = useContext(Context)

    const resultCatSubcat = product.category.map((category) => {
        const withCurrentId = product.subcategory.filter(subcategory => subcategory['categoryId'] === category['id'])
        const subcategory = withCurrentId.reduce((acc, current) => {
            acc.push(current)
            return acc
        }, [])
        return {...category, subcategory: subcategory}
    })

    return (
        <div className="nav__block">
            {resultCatSubcat.map(category =>
                <NavLink
                    style={{textDecoration: 'none'}}
                    onClick={() => product.setSelectedCategory(category)}
                    to='/category/product'
                    key={category.id}
                    className='nav__link'>
                    <div className='nav__btn '>
                        <div className="nav__img">
                            <img src={arrow} alt={arrow}/>
                        </div>
                        {category.name}
                    </div>
                </NavLink>
            )}
        </div>
    );
});

export default NavAdmin;