import React, {useContext, useEffect, useState} from 'react';
import './Reklama.css'
import no_photo from "../../accets/img/no_photo.png";
import {observer} from "mobx-react-lite";
import ok from "../../accets/img/ok.png";
import del from "../../accets/img/delete.png";
import {createReklama, deleteReklama, fetchReklama} from "../../http/reklamaAPI";
import {Context} from "../../index";

const Reklama = () => {

    const {rek} = useContext(Context)

    const [fileRek, setFileRek] = useState(null)
    const [vis, setVis] = useState(false)

    useEffect(() => {
        fetchReklama().then(data => rek.setRek(data))
        if (rek.rek[0]) {
            setVis(true)
        }
        if (!rek.rek[0]) {
            setVis(false)
        }
    }, [rek.rek])

    const addReklama = () => {
        if (!fileRek) {
            alert('Не выбран файл рекламы!')
        }
        if (fileRek) {
            const formData = new FormData()
            formData.append('img', fileRek)
            createReklama(formData).then(data =>
                setFileRek(null)
            )
        }
    }

    const selectFileReklama = e => {
        setFileRek(e.target.files[0])
    }

    const reklamaObj = rek.rek.reduce((acc, current) => {
        acc = current
        return acc
    }, {})

    const deleteReklamaBtn = () => {
        deleteReklama(reklamaObj.id)
    }

    return (
        <div>
            <div className="admin_panel_txt">Реклама</div>
            <div className="admin_panel admin_panel_users">
                <div className="photo_reklama_block">
                    {vis
                        ?
                        <div className='photo_reklama_img'>
                            <img src={process.env.REACT_APP_API_URL + reklamaObj.img} alt={reklamaObj.img}/>
                        </div>
                        : <div className='photo_reklama_img'>
                            <img src={no_photo} alt={no_photo}/>
                            <h3>Реклама не загружена</h3>
                        </div>
                    }
                    <div className="photo_reklama_block">
                        <div style={{textAlign: 'center'}}>
                            <div className="file-upload file-upload_rek">
                                <p>Выберите фото</p>
                                <input onChange={selectFileReklama} type="file"/>
                            </div>
                            <div onClick={addReklama} className="photo_reklama_btn_block">
                                <img src={ok} alt={ok}/>
                                <div className="photo_reklama_btn">Добавить</div>
                            </div>
                            <div onClick={deleteReklamaBtn}
                                 className="photo_reklama_btn_block photo_reklama_btn_block_red">
                                <img src={del} alt={del}/>
                                <div className="photo_reklama_btn photo_reklama_btn_red">Удалить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Reklama);