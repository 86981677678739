import React from 'react';
import './Preload.css'
import logo_mini from '../../accets/img/Logo_mini.png'

const Preload = () => {
    return (
        <div className='preload_block'>
            <div className="preload">
                <img src={logo_mini} alt={logo_mini}/>
                <div className="preload_text">Загрузка...</div>
            </div>
        </div>
    );
};

export default Preload;