import React, {useEffect, useState} from 'react';
import '../BasketZero/BasketZero.css'
import {fetchReklama} from "../../http/reklamaAPI";

const VisibleReklama = ({show, setShow}) => {

    const [rek, setRek] = useState([])

    useEffect(() => {
        fetchReklama().then(data => setRek(data))
    }, [])

    const reklamaObj = rek.reduce((acc, current) => {
        acc = current
        return acc
    }, {})

    const exitErrorLogin = () => {
        setShow(false)
    }

    return (
        <div style={{display: show ? 'flex' : 'none'}} className='basket_zero_block'>
            <div className="visible_reklama">
                <img src={process.env.REACT_APP_API_URL + reklamaObj.img} alt={reklamaObj.img}/>
                <div onClick={exitErrorLogin} className="basket_zero_close"></div>
            </div>
        </div>
    );
};

export default VisibleReklama;