import {makeAutoObservable} from "mobx";

export default class ProductStore {

    constructor() {
        this._category = []
        this._subcategory = []
        this._product = []
        this._selectedCategory = {}
        this._selectedSubcategory = {}
        this._page = 1
        this._totalCount = 0
        this._limit = 10
        this._order = []
        makeAutoObservable(this)
    }

    setCategory(category) {
        this._category = category
    }

    setSubcategory(subcategory) {
        this._subcategory = subcategory
    }

    setProduct(product) {
        this._product = product
    }

    setSelectedCategory(category) {
        this._selectedCategory = category
    }

    setSelectedSubcategory(subcategory) {
        this._selectedSubcategory = subcategory
    }

    setTotalCount(count) {
        this._totalCount = count
    }

    setPage(page) {
        this._page = page
    }

    setOrder(order) {
        this._order = order
    }

    get category() {
        return this._category
    }

    get subcategory() {
        return this._subcategory
    }

    get product() {
        return this._product
    }

    get selectedCategory() {
        return this._selectedCategory
    }

    get selectedSubCategory() {
        return this._selectedSubcategory
    }

    get totalCount() {
        return this._totalCount
    }

    get page() {
        return this._page
    }

    get limit() {
        return this._limit
    }

    get order() {
        return this._order
    }


}