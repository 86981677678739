import React, {useContext, useState} from 'react';
import './Nav.css'
import {useNavigate} from "react-router-dom";
import basket from '../../accets/img/basket.png'
import arrow from '../../accets/img/arrow.png'
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import BasketZero from "../BasketZero/BasketZero";
import {fetchProduct} from "../../http/productAPI";

const Nav = observer(({order}) => {

    const {product} = useContext(Context)

    const [show, setShow] = useState(false)

    const navigate = useNavigate()

    const SubCategoryAll = (subcategory) => {
        setTimeout(() => {
            fetchProduct(null, product.setSelectedSubcategory(subcategory), 1, 90).then(data => {
                product.setProduct(data.rows)
                product.setTotalCount(data.count)
            }).finally(() => navigate("/category/product"))
        }, 25)
    }

    const CategoryAll = (category) => {
        product.setSelectedCategory(category)
        navigate("/category/productCat")
    }



    const resultCatSubcat = product.category.map((category) => {
        const withCurrentId = product.subcategory.filter(subcategory => subcategory['categoryId'] === category['id'])
        const subcategory = withCurrentId.reduce((acc, current) => {
            acc.push(current)
            return acc
        }, [])
        return {...category, subcategory: subcategory}
    })

    return (
        <div className="nav__block">
            <div className="nav__link nav__link__basket" onClick={() => {
                if (order.length) {
                    navigate('/adminpanel/basket')
                } else {
                    setShow(true)
                }
            }} >
                <div className="basket__btn basket__btn__prod">
                    <img src={basket} alt={basket}/>
                    Корзина
                    <div className="basket__num">{order.length}</div>
                </div>
            </div>
            <ul>
                {resultCatSubcat.map(category =>
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={() => CategoryAll(category)}
                        key={category.id}
                        className={category.id === product.selectedCategory.id ? 'nav__link active' : 'nav__link'}>
                        <div className='nav__btn '>
                            <div className="nav__img">
                                <img src={arrow} alt={arrow}/>
                            </div>
                            {category.name}
                        </div>
                        {category.subcategory.map(subcategory =>
                            <div
                                className={
                                subcategory.id === product.selectedSubCategory.id
                                    ? "subcategory_block active"
                                    : "subcategory_block"
                            }
                                key={subcategory.id}
                                onClick={() => SubCategoryAll(subcategory)}
                            >
                                <img src={process.env.REACT_APP_API_URL + subcategory.img} alt={subcategory.img}/>
                                <div className="subcategory_name">{subcategory.name}</div>

                            </div>
                        )}
                    </div>
                )}
            </ul>
            <BasketZero show={show} setShow={setShow}/>

        </div>
    );
});

export default Nav;