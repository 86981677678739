import React, {useState} from 'react';
import './MenuBurger.css'
import BasketZero from "../BasketZero/BasketZero";
import NavAdaptive from "../NavAdaptive/NavAdaptive";

const MenuBurger = ({order}) => {
    const [menuActive, setMenuActive] = useState(false)

    return (
        <div className="nav_btn_visible_block" onClick={() => setMenuActive(!menuActive)}>
            <div className="nav_btn_visible_line"></div>
            <NavAdaptive menuActive={menuActive} setMenuActive={setMenuActive} order={order}/>
        </div>
    );
};

export default MenuBurger;