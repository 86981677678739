import React, {useContext, useEffect} from 'react';
import './UpdateProduct.css'
import {fetchProduct} from "../../http/productAPI";
import {Context} from "../../index";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const UpdateProduct = () => {

    const {product} = useContext(Context)

    const navigate = useNavigate()

    useEffect(() => {
        fetchProduct(null, null, 1, 100).then(data => {
            product.setProduct(data.rows)
            product.setTotalCount(data.count)
        })
    }, [])

    return (
        <div className='update_product_block'>
            <div className="update_product">
                <div className="update_product_title">Товары</div>
                <div className="update_product_spisok_block">
                    {product.product.map(prod =>
                    <div key={prod.id} className="update_product_item">
                        <div className="update_product_name">{prod.name}</div>
                        <div className="update_product_price">{prod.price}</div>
                        <div className="admin_panel_btn_add">
                            <div onClick={() => navigate('/adminpanel/update/' + prod.id)} className="admin_panel_btn_txt">Редактировать</div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(UpdateProduct);