import {$host, $authHost} from "./index";

export const createReklama = async (reklama) => {
    const {data} = await $authHost.post('api/reklama', reklama)
    return data
}

export const fetchReklama = async () => {
    const {data} = await $host.get('api/reklama')
    return data
}

export const fetchOneReklama = async (id) => {
    const {data} = await $host.get('api/reklama/' + id)
    return data
}

export const deleteReklama = async (id) => {
    const {data} = await $host.delete('api/reklama/' + id)
    return data
}

export const updateReklama = async (updateOneReklama) => {
    const {data} = await $authHost.put('api/reklama', updateOneReklama)
    return data
}