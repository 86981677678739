import React, {useContext, useEffect, useState} from 'react';
import './Product.css'
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {fetchProduct} from "../../http/productAPI";
import Preload from "../Preload/Preload";

const Product = observer(({order, setOrder, plusOneProduct, minusOneProduct, addOrder, info, setInfo, addInfo, minusOneInfo, plusOneInfo}) => {

    const {product} = useContext(Context)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchProduct(null, null, 1, 90).then(data => {
            product.setProduct(data.rows)
            product.setTotalCount(data.count)
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        fetchProduct(product.selectedCategory.id, product.selectedSubCategory.id, product.page, 90).then(data => {
            product.setProduct(data.rows)
            product.setTotalCount(data.count)
        }).finally(() => setLoading(false))
    }, [product.page, product.selectedCategory, product.selectedSubCategory])

    if (loading) {
        return <Preload/>
    }

    const addArreyProduct = (product) => {
        addOrder(product)
        addInfo(product)
    }

    const plusOneArrey = (id) => {
        plusOneProduct(id)
        plusOneInfo(id)
    }

    const minusOneArrey = (id) => {
        minusOneProduct(id)
        minusOneInfo(id)
    }

    return (
        <div className="prod__all_block" id="style-1">
            {product.product.map(product =>
                <div key={product.id} className="prod__block">
                    <div className="prod__img">
                        <img src={process.env.REACT_APP_API_URL + product.img} alt={product.img}/>
                        <div className="prod__name">
                            {product.name}
                        </div>
                        <div className="prod__info__block">
                            <div className="prod__description">
                                {product.description}
                            </div>
                        </div>
                    </div>
                    <div className="prod__price__basket">
                        <div className="prod__price">{product.price} P</div>
                        {order.findIndex(prod => prod.id === product.id) > -1
                            ? <div className="col_pm col_pm_prod">
                                <div onClick={() => minusOneArrey(product.id)} className="col_btm col_btm_prod">-</div>
                                <div className="col_num col_num_prod">
                                    {order.find(prod => prod.id === product.id).count}
                                </div>
                                <div onClick={() => plusOneArrey(product.id)} className="col_btm col_btm_prod">+</div>
                            </div>
                            : <div onClick={() => addArreyProduct(product)} className="basket__btn">В корзину</div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
});

export default Product;