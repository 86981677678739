import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import './PhoneStyle.css'
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {auth} from "../../firebase/firebase";
import PhoneInput from "react-phone-input-2";
import ErrorReg from "../ErrorLogin/ErrorReg";
import Preload from "../Preload/Preload";

const Registration = observer(() => {

        const navigate = useNavigate()

        const {user} = useContext(Context)
        const history = useNavigate()
        const [name, setName] = useState('')
        const [phone, setPhone] = useState('')
        const [userOtp, setUserOtp] = useState(null)
        const [otpR, setOtpR] = useState('')
        const [hasError, setHasError] = useState(false)
        const [show, setShow] = useState(false)
        const [visOtp, setVisOtp] = useState(false)
        const [nav, setNav] = useState(false)


        useEffect(() => {
            if (localStorage.getItem('token') && user.isAuth) {
                navigate('/category/product')
            }
        }, [])

        const handleSubmit = event => {
            event.preventDefault();
        }

        const signIn = () => {
            user.registr(phone, name, setHasError, setVisOtp)

        }

        const sendOtp = async () => {
            try {
                const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
                    'size': 'invisible',
                    'callback': (response) => {
                        signIn()
                    },
                    'expired-callback': () => {

                    }
                })
                const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha)
                setUserOtp(confirmation)
            } catch (e) {
                console.error(e)
            }
        }


        const verifyOtpBtn = async () => {
            try {
                const data = await userOtp.confirm(otpR)
                user.setUserFirebase(data.user.providerData)
                setNav(true)
            } catch (e) {
                console.error(e)
            }
        }

        if (nav) {
            history('/category/product')
        }

        if (user.loading) {
            return <Preload/>
        }

        return (
            <div className="logins__block">
                <div className="box">
                    <span className="borderLine"></span>
                    <form onSubmit={handleSubmit}>
                        <h2>Регистрация</h2>
                        {!visOtp && <PhoneInput
                            country={'ru'}
                            value={phone}
                            onChange={(phone) => setPhone("+" + phone)}
                        />}
                        {visOtp && <div className="inputBox">
                            <input
                                value={otpR}
                                onChange={e => setOtpR(e.target.value)}
                                type="text"
                                required="required"/>
                            <span>Код из СМС</span>
                            <i></i>
                        </div>}
                        {!visOtp && <div className="inputBox inputBox_reg">
                            <input value={name}
                                   onChange={e => setName(e.target.value)}
                                   type="text"
                                   required="required"/>
                            <span>Ваше имя</span>
                            <i></i>
                        </div>}
                        <div id="recaptcha"></div>
                        {!visOtp && <div className="links">
                            <NavLink to="/login">Войти</NavLink>
                        </div>}
                        {!visOtp && <input onClick={sendOtp} type="submit" value="Регистрация"/>}
                        {visOtp && <input onClick={verifyOtpBtn} type="submit" value="Подтвердить"/>}
                    </form>
                </div>
                {hasError && <ErrorReg show={show} setShow={setShow} hasError={hasError} setHasError={setHasError}/>}
            </div>
        );
    })
;

export default Registration;