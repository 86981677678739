import React, {useContext, useEffect, useState} from 'react';
import './AdminPanel.css'
import ok from "../../accets/img/ok.png";
import task from '../../accets/img/task.png'
import {createCategory, createProduct, createSubcategory} from "../../http/productAPI";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Preload from "../Preload/Preload";
import {useNavigate} from "react-router-dom";
import {fetchUsers} from "../../http/userAPI";
import Reklama from "../Reklama/Reklama";

const AdminPanel = observer(() => {
    const {product, user} = useContext(Context)
    const nav = useNavigate()

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const [cat, setCat] = useState('')
    const [fileCat, setFileCat] = useState(null)
    const [selectedCat, setSelectedCat] = useState('')
    const [selectedCat2, setSelectedCat2] = useState('')

    const [subcat, setSubcat] = useState('')
    const [fileSubcat, setFileSubcat] = useState(null)
    const [selectedSubcat, setSelectedSubcat] = useState('')

    const [nameprod, setNameprod] = useState('')
    const [price, setPrice] = useState(0)
    const [fileProd, setFileProd] = useState(null)
    const [description, setDescription] = useState('')
    const [allUsers, setAllUsers] = useState([])

    useEffect(() => {
        fetchUsers().then(data => setAllUsers(data))
    }, [])


    useEffect(() => {
        if (user.user.role !== 'ADMIN') {
            nav('/category/product')
        }
    }, [user.user.role])

    const addCategory = () => {
        if (!fileCat) {
            alert('Не выбран файл категории')
        }
        if (fileCat) {
            setLoading(true)
            const formData = new FormData()
            formData.append('name', cat)
            formData.append('img', fileCat)
            createCategory(formData).then(data =>
                    setCat(''),
                setFileCat(null)
            )
            setLoading(false)
        }

    }

    const handleChangeCat = event => {
        setSelectedCat(event.target.value);
    };
    const handleChangeCat2 = event => {
        setSelectedCat2(event.target.value);
    };
    const handleChangeSubcat = event => {
        setSelectedSubcat(event.target.value);
    };

    const addSubcategory = () => {
        if (!fileSubcat) {
            alert('Не выбран файл подкатегории')
        }
        if (fileSubcat) {
            const formData = new FormData()
            formData.append('name', subcat)
            formData.append('img', fileSubcat)
            formData.append('categoryId', selectedCat)
            createSubcategory(formData).then(data =>
                    setSubcat(''),
                setFileSubcat(null),
                setSelectedCat('Выберите категорию')
            )
        }

    }

    const selectFileProduct = e => {
        setFileProd(e.target.files[0])
    }

    const selectFileCategory = e => {
        setFileCat(e.target.files[0])
    }

    const selectFileSubcategory = e => {
        setFileSubcat(e.target.files[0])
    }

    const addProduct = () => {
        if (!fileProd) {
            alert('Не выбран файл товара')
        }

        if (fileProd) {
            const formData = new FormData()
            formData.append('name', nameprod)
            formData.append('price', `${price}`)
            formData.append('img', fileProd)
            formData.append('description', description)
            formData.append('categoryId', selectedCat2)
            formData.append('subcategoryId', selectedSubcat)
            createProduct(formData).then(data =>
                    setNameprod(''),
                setPrice(0),
                setDescription(''),
                setFileProd(null),
                setSelectedCat2('Выберите категорию'),
                setSelectedSubcat('Выберите подкатегорию')
            )
        }


    }

    if (loading) {
        return <Preload/>
    }

    return (
        <div className="admin_panel_block">
            <div className="admin_panel_txt">Панель администратора</div>
            <div className="admin_panel">
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Добавить категорию</div>
                        <div className="admin_panel_input_block">
                            <input
                                type="text"
                                value={cat}
                                onChange={e => setCat(e.target.value)}
                                placeholder="Название категории"/>
                            <div className="file-upload">
                                <p>Выберите фото</p>
                                <input onChange={selectFileCategory} type="file"/>
                            </div>
                        </div>
                    </div>
                    <div className="admin_panel_btn_block">
                        <div className="admin_panel_btn_add">
                            <img src={ok} alt={ok}/>
                            <div onClick={addCategory} className="admin_panel_btn_txt">Добавить</div>
                        </div>
                    </div>
                </div>
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Добавить подкатегорию</div>
                        <div className="admin_panel_input_block">
                            <input
                                type="text"
                                value={subcat}
                                onChange={e => setSubcat(e.target.value)}
                                placeholder="Название подкатегории"
                            />
                            <select value={selectedCat} onChange={handleChangeCat}>
                                <option>Выберите категорию</option>
                                {product.category.map(category =>
                                    <option
                                        onClick={() => product.setSelectedCategory(category)}
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                )}
                            </select>
                            <div className="file-upload">
                                <p>Выберите фото</p>
                                <input onChange={selectFileSubcategory} type="file"/>
                            </div>
                        </div>
                    </div>
                    <div className="admin_panel_btn_block">
                        <div className="admin_panel_btn_add">
                            <img src={ok} alt={ok}/>
                            <div onClick={addSubcategory} className="admin_panel_btn_txt">Добавить</div>
                        </div>
                    </div>
                </div>
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Добавить товар</div>
                        <div className="admin_panel_input_block">
                            <input
                                value={nameprod}
                                onChange={e => setNameprod(e.target.value)}
                                type="text"
                                placeholder="Название товара"/>
                            <select value={selectedCat2} onChange={handleChangeCat2}>
                                <option>Выберите категорию</option>
                                {product.category.map(category =>
                                    <option
                                        onClick={() => product.setSelectedCategory(category)}
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                )}
                            </select>
                            <select value={selectedSubcat} onChange={handleChangeSubcat}>
                                <option>Выберите категорию</option>
                                {product.subcategory.map(subcategory =>
                                    <option
                                        onClick={() => product.setSelectedSubcategory(subcategory)}
                                        key={subcategory.id}
                                        value={subcategory.id}
                                    >
                                        {subcategory.name}
                                    </option>
                                )}
                            </select>
                            <div className="file-upload">
                                <p>Выберите фото</p>
                                <input onChange={selectFileProduct} type="file" id="file" accept="image/*"/>
                            </div>
                        </div>
                    </div>
                    <div className="admin_panel_btn_block">
                        <div className="admin_panel_btn_add">
                            <img src={ok} alt={ok}/>
                            <div onClick={addProduct} className="admin_panel_btn_txt">Добавить</div>
                        </div>
                        <div onClick={() => navigate('/adminpanel/update')} className="admin_panel_btn_sps">
                            <img src={task} alt={task}/>
                            <div className="admin_panel_btn_txt">Список</div>
                        </div>
                    </div>
                </div>
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Характеристика товара</div>
                        <div className="admin_panel_input_block">
                            <textarea
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                type="text"
                                placeholder="Характеристика товара..."/>
                        </div>
                    </div>
                </div>
                <div className="admin_panel_line">
                    <div className="admin_panel_line_block">
                        <div className="admin_panel_name">Цена</div>
                        <div className="admin_panel_input_block admin_panel_input_block_price">
                            <input
                                value={price}
                                onChange={e => setPrice(Number(e.target.value))}
                                type="text"
                                placeholder=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin_panel_reklama_block">
                <div>
                    <div className="admin_panel_txt">Пользователи</div>
                    <div id="style-2" className="admin_panel admin_panel_users">
                        <div className="admin_panel_users_list_block">
                            <div className="admin_panel_users_list">Имя пользователя</div>
                            <div className="admin_panel_users_list">телефон</div>
                        </div>
                        {allUsers.map(item =>
                            <div key={item.id} style={{marginTop: '5px'}} className="admin_panel_users_list_block">
                                <div className="admin_panel_users_list">{item.name}</div>
                                <div className="admin_panel_users_list">{item.phone}</div>
                            </div>
                        )}
                    </div>
                    <div className="admin_panel_user_result_block">
                        <div className="admin_panel_user_result_text">Зарегистрировано на сайте:</div>
                        <div className="admin_panel_user_result_text">{allUsers.length}</div>
                    </div>
                </div>
                <Reklama/>
            </div>
        </div>
    );
});

export default AdminPanel;