import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import UserStore from "./store/UserStore";
import ProductStore from "./store/ProductStore";
import AddressStore from "./store/AddressStore";
import RekStore from "./store/RekStore";

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{
        user: new UserStore(),
        product: new ProductStore(),
        adr: new AddressStore(),
        rek: new RekStore()
    }}>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </Context.Provider>


);

reportWebVitals();
