import './App.css';
import Header from "./components/Header/Header";
import NavAndProduct from "./components/NavAndProduct/NavAndProduct";
import React, {useContext, useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import Category from "./components/Category/Category";
import Login from "./components/Login/Login";
import Registration from "./components/Login/Registration";
import {observer} from "mobx-react-lite";
import {fetchOrder} from "./http/orderAPI";
import {Context} from "./index";
import {fetchCategory, fetchSubcategory} from "./http/productAPI";
import Preload from "./components/Preload/Preload";
import NavAndOrder from "./components/NavAndOrder/NavAndOrder";
import {fetchAddress} from "./http/addressAPI";
import {fetchReklama} from "./http/reklamaAPI";
import MenuBurger from "./components/MenuBurger/MenuBurger";

const App = observer(() => {

    const {product, user, adr, rek} = useContext(Context)

    const [order, setOrder] = useState([])
    const [info, setInfo] = useState([])
    const [loading, setLoading] = useState(true)

    const addOrder = (product) => {
        setOrder(prev => [...prev, {
            ...product,
            count: 1
        }])
    }
    const addInfo = (product) => {
        setInfo(prev => [...prev, {
            productId: product.id,
            count: 1
        }])
    }

    const plusOneProduct = (id) => {
        setOrder(prev => prev.map(item => {
            if (item.id === id) {
                return {...item, count: item.count + 1}
            }
            return item
        }))
    }
    const plusOneInfo = (id) => {
        setInfo(prev => prev.map(item => {
            if (item.productId === id) {
                return {...item, count: item.count + 1}
            }
            return item
        }))
    }

    const minusOneProduct = (id) => {
        let count = order.find(prod => prod.id === id).count
        if (count === 1) {
            setOrder(prev => prev.filter(item => item.id !== id))
        } else {
            setOrder(prev => prev.map(item => {
                    if (item.id === id) {
                        return {...item, count: item.count - 1}
                    }
                    return item
                })
            )
        }

    }
    const minusOneInfo = (id) => {
        let count = info.find(prod => prod.productId === id).count
        if (count === 1) {
            setInfo(prev => prev.filter(item => item.productId !== id))
        } else {
            setInfo(prev => prev.map(item => {
                    if (item.productId === id) {
                        return {...item, count: item.count - 1}
                    }
                    return item
                })
            )
        }

    }

    const delProduct = (id) => {
        setOrder(prev => prev.filter(item => item.id !== id))
        setInfo(prev => prev.filter(item => item.id !== id))
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            user.checkAuth()
        }

        if (localStorage.getItem('order') !== null) {
            setOrder(JSON.parse(localStorage.getItem('order')))
        }
        if (localStorage.getItem('info') !== null) {
            setOrder(JSON.parse(localStorage.getItem('info')))
        }
        fetchAddress().then(data => adr.setAllAddress(data))
        fetchCategory().then(data => product.setCategory(data))
        fetchSubcategory().then(data => product.setSubcategory(data))
        fetchReklama().then(data => rek.setRek(data))
        fetchOrder().then(data => product.setOrder(data)).finally(setLoading(false))

    }, [])

    useEffect(() => {
        localStorage.setItem('order', JSON.stringify(order))
        localStorage.setItem('info', JSON.stringify(info))
    }, [order, info])

    if (loading) {
        return <Preload/>
    }

    return (
        <div className="App">
            <Header/>
            <MenuBurger order={order}/>
            <Routes>
                <Route path="/" element={<Category/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/registration" element={<Registration/>}/>
                <Route path="/category/*" element={<NavAndProduct
                    delProduct={delProduct}
                    addOrder={addOrder}
                    plusOneProduct={plusOneProduct}
                    minusOneProduct={minusOneProduct}
                    order={order}
                    setOrder={setOrder}
                    info={info}
                    setInfo={setInfo}
                    addInfo={addInfo}
                    minusOneInfo={minusOneInfo}
                    plusOneInfo={plusOneInfo}
                />}/>
                <Route path="/adminpanel/*" element={<NavAndOrder
                    delProduct={delProduct}
                    addOrder={addOrder}
                    plusOneProduct={plusOneProduct}
                    minusOneProduct={minusOneProduct}
                    order={order}
                    setOrder={setOrder}
                    info={info}
                    setInfo={setInfo}
                    addInfo={addInfo}
                    minusOneInfo={minusOneInfo}
                    plusOneInfo={plusOneInfo}
                />}/>
            </Routes>


        </div>
    );
})

export default App;
